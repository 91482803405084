//
// Table editable
//

.table-edits {

  input,
  select {
    height: $input-height-sm;
    padding: $input-padding-y-sm $input-padding-x-sm;
    border: 1px solid $input-border-color;
    background-color: $input-bg;
    color: $input-color;
    border-radius: $input-border-radius;

    &:focus {
      outline: none;
      border-color: $input-focus-border-color;
    }
  }
}

.property-Detail table tr {
  th {
    width: 50%;
    padding: 8px 0;
  }

  td {
    width: 50%;
  } 
span {
  padding-top: 8px;
  display: inline-block;
}
}
.advocate th { width: 50%;}

// profile 

.update-image-container .upload-icon {
    background: rgba(5,5,5,.77);
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    border-radius: 50%;
    width: 35px;
    position: absolute;
    height: 35px;
    bottom: 0;
    right: 0;
    margin: 0;
}