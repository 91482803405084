@import "./variables-dark";

.noti-icon .badge {
  left: 23px;
}
.mt-2 {
  margin-top: 20px !important;
}

//tags
.tags-input-container {
  border: 1px solid #ced4da;
  padding: 0.5em;
  border-radius: 3px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;

  .tags-input {
    flex-grow: 1;
    padding: 0.5em 0;
    border: none;
    // outline: none;
  }

  .tag-item {
    background-color: rgb(218, 216, 216);
    display: inline-flex;
    padding: 0.5em 0.75em;
    border-radius: 20px;

    .close-tag {
      height: 20px;
      width: 20px;
      background-color: rgb(48, 48, 48);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-left: 0.5em;
      font-size: 18px;
      color: white;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}

.login-btn {
  background-color: $primary;
  border: none;
  transition: 0.5s all ease;
  border: 2px solid transparent;
  color: white;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    background-color: transparent !important;
    border: 2px solid #0080ff;
    color: black !important;
  }
}

.btn-success {
  background-color: $primary !important;
  &:hover{
    background-color: #0080ff !important;
  }
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}
.cursor-pointer {
  cursor: pointer;
}
.subscription-page {
  .btn-group-example {
    button {
      font-size: 14px !important;
      padding: 7px 16px;

      &:focus {
        box-shadow: none;
      }
      
      &:hover {
        background-color: $primary !important;
        transition: 0.4s all ease-in-out;
      }
    }
  }

  .active-subscription-status {
    background-color: $primary;
    color: white;
  }
}
.badge {
  font-size: 12px !important;
}
.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}
